import { UserLinkType } from './users.types';
import { CommentType } from './comments.types';
import { Newbuilding, NewBuildingHouse } from './newbuildings.types';
import { OwnerPhone } from './owners.types';

export const ESTATE_BASE_ALL = 'all';
export const ESTATE_BASE_MLS = 'mls';
export const ESTATE_BASE_MY = 'my';
export const ESTATE_BASE_GROUP = 'group';
export const ESTATE_BASE_MAIN = 'crm';
export const ESTATE_BASE_OWNERS = 'owners';
export const ESTATE_BASE_EXTERNAL = 'external';
export const ESTATE_BASE_FAVORITES = 'favorites';
export const ESTATE_BASE_FREE = 'free';
export const ESTATE_BASE_FOREIGN = 'foreign';

export const ESTATE_PROPERTY_TYPE_FLAT = 1;
export const ESTATE_PROPERTY_TYPE_NEWBUILDING = 2;
export const ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION = 6;
export const ESTATE_PROPERTY_TYPE_PART = 4;
export const ESTATE_PROPERTY_TYPE_ROOM = 3;

export const ESTATE_PROPERTY_TYPE_NEWBUILDINGS_ARR = [ESTATE_PROPERTY_TYPE_NEWBUILDING, ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION];

export const ESTATE_PROPERTY_FLATS_ARR = [
    ESTATE_PROPERTY_TYPE_FLAT,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_PART,
    ESTATE_PROPERTY_TYPE_ROOM
];

export const ESTATE_PROPERTY_TYPE_HOUSE = 11;
export const ESTATE_PROPERTY_TYPE_COTTAGE = 12;
export const ESTATE_PROPERTY_TYPE_TOWNHOUSE = 13;
export const ESTATE_PROPERTY_TYPE_HOUSE_PART = 14;

export const ESTATE_PROPERTY_TYPE_LAND = 17;

export const ESTATE_PROPERTY_HOUSES = [
    ESTATE_PROPERTY_TYPE_HOUSE,
    ESTATE_PROPERTY_TYPE_COTTAGE,
    ESTATE_PROPERTY_TYPE_TOWNHOUSE,
    ESTATE_PROPERTY_TYPE_HOUSE_PART
];

export const ESTATE_PROPERTY_COUNTRY_ARR = [...ESTATE_PROPERTY_HOUSES, ESTATE_PROPERTY_TYPE_LAND];

export const ESTATE_PROPERTY_TYPE_GARAGE = 201;
export const ESTATE_PROPERTY_TYPE_GARAGE_BOX = 202;
export const ESTATE_PROPERTY_TYPE_CAR_PLACEMENT = 203;

export const ESTATE_PROPERTY_GARAGE_ARR = [
    ESTATE_PROPERTY_TYPE_GARAGE,
    ESTATE_PROPERTY_TYPE_GARAGE_BOX,
    ESTATE_PROPERTY_TYPE_CAR_PLACEMENT
];

export const ESTATE_PROPERTY_TYPE_OFFICE = 101;
export const ESTATE_PROPERTY_TYPE_FREE_PURPOSE = 102;
export const ESTATE_PROPERTY_TYPE_COMMERCE_LAND = 103;
export const ESTATE_PROPERTY_TYPE_WAREHOUSE = 104;
export const ESTATE_PROPERTY_TYPE_SHOPPING = 105;
export const ESTATE_PROPERTY_TYPE_BUSINESS = 106;
export const ESTATE_PROPERTY_TYPE_INDUSTRY = 107;
export const ESTATE_PROPERTY_TYPE_BUILDING = 108;

export const ESTATE_PROPERTY_COMMERCE_ARR = [
    ESTATE_PROPERTY_TYPE_OFFICE,
    ESTATE_PROPERTY_TYPE_FREE_PURPOSE,
    ESTATE_PROPERTY_TYPE_COMMERCE_LAND,
    ESTATE_PROPERTY_TYPE_WAREHOUSE,
    ESTATE_PROPERTY_TYPE_SHOPPING,
    ESTATE_PROPERTY_TYPE_BUSINESS,
    ESTATE_PROPERTY_TYPE_INDUSTRY,
    ESTATE_PROPERTY_TYPE_BUILDING
];

export const DISABLED_RENT_PROPERTY_TYPES = [
    ESTATE_PROPERTY_TYPE_NEWBUILDING,
    ESTATE_PROPERTY_TYPE_PART,
    ESTATE_PROPERTY_TYPE_NEWBUILDING_CESSION,
    ESTATE_PROPERTY_TYPE_LAND,
    ESTATE_PROPERTY_TYPE_HOUSE_PART
];

export const ESTATE_TYPE_SELL = 'sell';
export const ESTATE_TYPE_SELL_ID = 1;
export const ESTATE_TYPE_RENT = 'rent';
export const ESTATE_TYPE_RENT_ID = 2;

export type ESTATE_BASES =
    | 'all'
    | 'mls'
    | 'crm'
    | 'owners'
    | 'my'
    | 'external'
    | 'group'
    | 'favorites'
    | 'free'
    | 'withoutExternal'
    | 'foreign';

export const TRANSPORT_TYPE_WALK = 1;
export const TRANSPORT_TYPE_TRANSPORT = 2;

export const AGENT_BONUS_TYPE_FIXED = 1;
export const AGENT_BONUS_TYPE_PERCENT = 2;

export type EstateAddressStrict = {
    address: string;
    fias: string | null;
    kladr: string | null;
    geo_lat: number | null;
    geo_lon: number | null;

    street: string | null;
    streetType: string | null;
    houseNumber?: string | null;
    houseNumberType?: string | null;
    blockNumber?: string | null;
    blockNumberType?: string | null;

    town: string | null;
    townType: string | null;
    region: string;
    regionType: string | null;
    district?: string | null;
    districtType?: string | null;
    cityDistrict?: string | null;
    cityDistrictType?: string | null;
    settlement?: string | null;
    settlementType?: string | null;
    postcode: number | null;
};

export type EstateAddress = EstateAddressStrict & {
    flatNumber?: string | null;
    totalArea?: number | null;
};

export type EstateLinkType = {
    readonly estate_id: number;
    type: number;
    address: string;
    houseNumberAdjusted: string;
    roomsCount: number | null;
    propertyType: number;
    enable?: boolean;
};

export type EstateLinkTypeWithBase = EstateLinkType & {
    base: ESTATE_BASES;
};

export type EstateCommerce = {
    minArea: number | null;
    conditionType: number | null;
    isOccupied: boolean | null;
    layout: number | null;
    furniturePresence: boolean | null;
    isLegalAddressProvided: boolean | null;
    availableFrom: number | null;
    operationalCostsIncluded: boolean | null;
    waterPipesCount: number | null;
    taxNumber: number | null;

    parkingType: number | null;
    parkingPlacesCount: number | null;
    parkingPriceMonthly: number | null;
    parkingCurrency: number | null;
    parkingIsFree: boolean | null;
    parkingLocationType: number | null;

    totalAreaBuilding: number | null;
    buildingName: string | null;
    buildingType: number | null;
    buildingClassType: number | null;
    developer: string | null;
    managementCompany: string | null;
    ventilationType: number | null;
    conditioningType: number | null;
    extinguishingSystemType: number | null;
    statusType: number;
    accessType: number | null;
    businesshouse_id: number | null;
    heatingType: number | null;
    ceilingHeight: number | null;

    hasCarWash: boolean | null;
    hasBuffet: boolean | null;
    hasCarService: boolean | null;
    hasCanteen: boolean | null;
    hasCentralReception: boolean | null;
    hasHotel: boolean | null;
    hasAtm: boolean | null;
    hasExhibitionAndWarehouseComplex: boolean | null;
    hasPharmacy: boolean | null;
    hasBankDepartment: boolean | null;
    hasCinema: boolean | null;
    hasCafe: boolean | null;
    hasMedicalCenter: boolean | null;
    hasBeautyShop: boolean | null;
    hasStudio: boolean | null;
    hasNotaryOffice: boolean | null;
    hasPool: boolean | null;
    hasClothesStudio: boolean | null;
    hasWarehouse: boolean | null;
    hasPark: boolean | null;
    hasRestaurant: boolean | null;
    hasFitnessCentre: boolean | null;
    hasSupermarket: boolean | null;
    hasMinimarket: boolean | null;
    hasShoppingArea: boolean | null;
    hasConferenceRoom: boolean | null;

    landArea: number | null;
    landAreaUnitType: number;
    landType: number;

    vatType: number;
    contractType: number | null;

    inputType: number | null;
    hasShopWindows: boolean | null;
};

export type EstateWarehouse = {
    floorMaterialTypeType: number | null;
    parkingPurposeType: number | null;
    parkingPriceEntry: number | null;
    liftType: number | null;
    liftAdditionalType: string | null;
    liftCount: number | null;
    liftLoadCapacity: number | null;
    cranageType: number | null;
    cranageLoadCapacity: number | null;
    cranageCount: number | null;
    gatesType: number | null;
    columnGrid: string | null;
    hasSafeCustody: boolean | null;
    isCustoms: boolean | null;
    hasTransportServices: boolean | null;
    hasOfficeSpace: boolean | null;
};

export type EstateLand = {
    settlementName?: string | null;
    settlement_cian_id: number | null;

    landArea: number;
    landAreaUnitType: number;
    landStatus?: number | null;

    highway_id?: number | null;
    distanceFromMKAD?: number | null;

    hasGas: boolean | null;
    hasDrainage: boolean | null;
    hasElectricity: boolean | null;
    hasWater: boolean | null;
};

export type EstateGarage = {
    garageType: number | null;
    materialType: number | null;
    garageStatus: number | null;
    totalArea: number | null;

    hasLight: boolean | null;
    hasElectricity: boolean | null;
    hasWater: boolean | null;
    hasHeating: boolean | null;
    hasExtinguishingSystem: boolean | null;

    buildingName: string | null;
    parkingType: number | null;

    hasCarWash: boolean | null;
    hasCarService: boolean | null;
    hasTire: boolean | null;
    hasInspectionPit: boolean | null;
    hasVideoSurveillance: boolean | null;
    hasHourSecurity: boolean | null;
    hasAutomaticGates: boolean | null;
    hasEntryByPass: boolean | null;
    hasBasement: boolean | null;
};

export type EstateCountry = EstateLand & {
    totalArea: number | null;
    bedroomsCount?: number | null;
    wcLocationType: number | null;
    repairType: number | null;

    floorsCount?: number | null;
    buildYear?: number | null;
    materialType?: number | null;
    heatingType?: number | null;

    hasPhone: boolean | null;
    hasSecurity: boolean | null;
    hasBathhouse: boolean | null;
    hasGarage: boolean | null;
    hasPool: boolean | null;

    lotCadastralNumber: string | null;
};

export type EstateTableItem = EstateLinkType & {
    description: string;
    metros: MetroDistance[];
    totalArea?: number | null;
    livingArea?: number | null;
    kitchenArea?: number | null;
    allRoomsArea?: string;
    floorNumber?: number | null;
    floorsCount?: number | null;
    materialType?: number | null;
    major_users: UserLinkType[];
    export_users?: UserLinkType[];
    createTime: number;
    updateTime: number;
    price: number;
    currency: number;
    photosCount: number;
    youtubeId?: string | null;
    exportingBaseCount?: number;
    exportingErrorsCount?: number;

    agentBonusType: 1 | 2 | null;
    agentBonusValue: number | null;
    agentBonusCurrency: 1 | 2 | 3;

    main_metro_id?: number;

    cityDistrict?: string | null;
    cityDistrictType?: string | null;

    buildYear?: number | null;
    buildingIsNotReadyYet?: boolean | null;

    owner_source_id?: number | null;
    source?: string | null;
    sourceInnerId?: string | null;
    agencyName?: string | null;

    externalPhones?: { phone_id: number; phone: string }[];

    newbuilding_id?: number | null;
    newbuilding?: Newbuilding;
    newbuilding_house_id?: number | null;
    newbuildingHouse?: NewBuildingHouse;

    showingsCount?: number;
    callsCount?: number;
    lastOutgoingCallToOwnerTime?: number;
    comments?: CommentType[];

    landArea?: number;
    landAreaUnitType?: number;

    isFake: boolean | null;
    isTop3: boolean | null;
    isAlreadySold: boolean | null;
    exclusiveDealId: number | null;
    dealId: number | null;
    base: ESTATE_BASES;
    isFavorite: boolean;
    isHidden: boolean;
    isBooked?: boolean | null;
    whetherIsInSma: boolean | null;

    deleteStatus: number | null;
    soldPrice: number | null;

    ownerName?: string | null;
    inner_id: number | null;
    ownerPhones?: OwnerPhone[];
    mls_company_id: number | null;
    agency: MlsAgency | null;

    priceHistory: EstatePriceHistory[];
};

export type EstateCommerceLand = {
    possibleToChangeLandStatus?: boolean | null;
    permittedUseType?: number | null;
    possibleToChangePermittedUseType?: boolean | null;
    hasInvestmentProject?: boolean | null;
    hasEncumbrances?: boolean | null;
    electricityLocationType?: number | null;
    electricityPossibleToConnect?: boolean | null;
    electricityPower?: number | null;
    gasLocationType?: number | null;
    gasPossibleToConnect?: boolean | null;
    gasCapacity?: number | null;
    gasPressureType?: number | null;
    drainageLocationType?: number | null;
    drainagePossibleToConnect?: boolean | null;
    drainageCapacity?: number | null;
    drainageType?: number | null;
    waterLocationType?: number | null;
    waterPossibleToConnect?: boolean | null;
    waterCapacity?: number | null;
    waterType?: number | null;
    drivewayType?: number | null;
};

export type EstateShopping = {
    placementType?: number | null;
    hasFoodCourt?: boolean | null;
    hasSlotMachines?: boolean | null;
    hasAquapark?: boolean | null;
    hasBabySitting?: boolean | null;
    hasRink?: boolean | null;
    hasBowling?: boolean | null;
    hasGameRoom?: boolean | null;
    workingDaysType?: number | null;
    tenants?: string | null;
    openingHoursType?: number | null;
    openingHoursFrom?: string | null;
    openingHoursTo?: string | null;
    shoppingCenterScaleType?: number | null;
    houseLineType?: number | null;
};

export type EstateBusiness = {
    businessPropertyType?: number | null;
    businessEstateType?: number | null;
    hasEquipment?: boolean | null;
    estateType?: number | null;
};

export type Estate = EstateTableItem &
    EstateCommerce &
    EstateCountry &
    EstateGarage &
    EstateCommerceLand &
    EstateWarehouse &
    EstateShopping &
    EstateBusiness &
    EstateAddressStrict & {
        shareAmount: string | null;
        roomsForSaleCount: number | null;
        roomType: number | null;

        newbuilding_id: number;
        newbuilding_house_id: number;
        buildingSeries: string | null;
        ceilingHeight: number | null;
        passengerLiftsCount: number;
        cargoLiftsCount: number;
        hasGarbageChute: boolean | null;
        hasRamp: boolean | null;
        parking: number;

        flatNumber: string;
        cadastralNumber: string | null;

        isApartments: boolean;
        hasOwnershipRight: boolean | null;
        isLayoutApproved: boolean | null;
        isPenthouse: boolean;
        windowsView: number;
        loggiasCount: number;
        balconiesCount: number;
        separateWcsCount: number;
        combinedWcsCount: number;
        repairType: number | null;
        decoration: number | null;
        hasPhone: boolean | null;

        mortgage: boolean;
        saleType: number;

        photos: UploadingPhoto[];

        major_user_ids: number[];
        export_user_ids?: number[];
        contact_id: number | null;

        hasInternet: boolean | null;
        hasFurniture: boolean | null;
        hasKitchenFurniture: boolean | null;
        hasTv: boolean | null;
        hasWasher: boolean | null;
        hasConditioner: boolean | null;
        hasBathtub: boolean | null;
        hasShower: boolean | null;
        hasDishwasher: boolean | null;
        hasFridge: boolean | null;
        petsAllowed: boolean | null;
        childrenAllowed: boolean | null;

        utilitiesIncluded: boolean | null;
        otherUtilities: boolean | null;
        otherUtilitiesPayment: number | null;
        bargainAllowed: boolean | null;
        bargainPrice: number | null;
        bargainConditions: string | null;
        leaseTermType: number;
        prepayMonths: number;
        deposit: number | null;
        clientFee: number | null;
        agentFee: number | null;

        subagent: MlsSubagentPerson;

        documentsCount: number;

        updateTime: number;
        enable: boolean;
    };

export type EstatePriceHistory = {
    estate_price_id: number;
    estate_id: number;
    price: number;
    user_id: number;
    currency: number;
    time: number;
};

export type MlsAgency = {
    mls_company_id: number;
    url: string;
    logoUrl: string;
    shortTitle: string;
    title: string;
    format: 'cian2' | 'yandex' | 'aristo';
    feedUrl: string;
    contacts: string;
    phone: string;
    color: string;

    createTime: number;
    updateTime: number;

    enable: boolean;
};

export type MlsFeedReport = {
    objectsCount: number;
    goodCount: number;
    deleteCount: number;
};

export type MlsSubagentPerson = {
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;
};

export type MetroDistance = {
    readonly metro_id: number;
    name?: string;
    distance?: number;
    color?: string;
    time: number;
    transportType: number;
};

export type UploadingPhoto = {
    filename: string;
    originalname?: string;
    rotate?: number;
    isPlan?: boolean;
    isLoading?: boolean;
    watermark?: number | null;
};

export type EstateFilterMain = {
    regionId: number;
    propertyType: number;
    propertyTypes: number[];
    type: number;

    priceMin: number | null;
    priceMax: number | null;
    priceM2Min: number | null;
    priceM2Max: number | null;

    totalAreaMin: number | null;
    totalAreaMax: number | null;
    livingAreaMin: number | null;
    livingAreaMax: number | null;
    kitchenAreaMin: number | null;
    kitchenAreaMax: number | null;
    floorNumberMin: number | null;
    floorNumberMax: number | null;
    floorsCountMin: number | null;
    lastFloorOnly: boolean | null;

    materialType: number[] | null;
    buildYearMin: number | null;
    buildYearMax: number | null;

    roomsCount: number[] | null;

    main_metro_id: number[];
    transportType: number | null;
    transportTime: number | null;

    hasLoggia: boolean | null;
    hasBalcone: boolean | null;
    wcType: number | null;

    showApartmentsOnly: boolean | null;
    saleType: number | null;
};

export type EstateFilter = EstateFilterMain & {
    base: ESTATE_BASES;

    group_id: number[] | null;
    major_user_id: number[] | null;
    enable: boolean;

    descriptionContains: string | null;
    streetContains: string | null;
    townContains: string | null;
    newbuildingContains: string | null;
    newbuilding_id: number | null;
    newbuilding_house_id: number | null;

    currency: number | null;
    showExportedOnly: boolean | null;
    showExportedTop3: boolean;
    showExportedBases: number[] | null;
    showNoExportedOnly: boolean | null;
    showExportingErrorsOnly: boolean;

    fias: string | null;
    search: string | null;

    showExportMajorUser: boolean | null;
    fakesAvailable: boolean | null;
    byCreateTime: boolean | null;
    showAvailableOnly: boolean | null;

    noAdsCallsTime: number | null;
    noOutgoingToOwnerCallsTime: number | null;
    isAlreadySold: boolean | null;

    hasBonusToAgent: boolean | null;

    startTime: number | null;
    endTime: number | null;
    deltaTime: number | null;
};

export type MetroStation = {
    readonly metro_id: number;
    name: string;
    color: string;
    order: number;
    metro_line_id: number;
    yandex_id?: string;
};

export type CoordinatesCorner = {
    lat: number;
    lng: number;
};

export type CoordinatesSquare = {
    bottomLeft: CoordinatesCorner;
    topRight: CoordinatesCorner;
};

export type ManageEstateArrayType = {
    major_user_ids: number[];
    export_user_id: number;
    disableAll: boolean;
    disableBasesIds: number[];
    enableBasesIds: number[];
    setToFree: boolean;
};

export type TEstateDropdown = EstateTableItem & { photos: UploadingPhoto[] };

export type ShareEstateId = {
    estate_id: number;
    base: ESTATE_BASES;
};

export type MlsEstateFixPriceInBase = {
    estate_fix_price_id: number;
    major_user_id: number;
    database: string;

    agentFirstName: string;
    agentLastName: string;
    agentPhone: string;

    estate_id: number;
    base: ESTATE_BASES;

    status: number;
    phone: string;

    price: number;
    currency: number;

    agentBonusType: number | null;
    agentBonusValue: number | null;
    agentBonusCurrency: number | null;

    createTime: number;
    updateTime: number;
    enable: boolean;
};

export type MlsEstateFixPrice = MlsEstateFixPriceInBase & {
    estate: Estate | null;
};
