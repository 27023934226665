import fetchApi from '../common/fetchApi';
import { objectToGraphql } from '~/common/graphql';
import {
    CoordinatesSquare,
    Estate,
    ESTATE_BASE_EXTERNAL,
    ESTATE_BASES,
    EstateFilter,
    EstateTableItem,
    ManageEstateArrayType,
    MetroDistance,
    MetroStation,
    MlsEstateFixPrice,
    ShareEstateId,
    TEstateDropdown
} from '~/types/estate.types';
import { GQHistoryChange, GQUserMainFields } from './userApi';
import { ESTATE_BASE_MAIN, ESTATE_BASE_OWNERS } from '~/types/estate.types';
import { Highway, MOSCOWMAP_House } from '~/types/houses.types';
import { GQComments } from './commentsApi';
import { HistoryChange } from '~/types/historyChanges.types';
import { GQNewBuildingFields, GQNewBuildingHouseFields, GQAddressFields } from './newbuildingsApi';
import { OrderDirectionType } from '~/stores/prototypes/ListStore.prototype';
import { GQMlsFixPriceMainFields } from '~/api/fixMlsPriceApi';

export const GQMetroFiled = `
    metro_id
    name
    color
    time
    transportType    
`;

export const GQEstateMainFields = `
    estate_id
    type
    address
    houseNumberAdjusted
    roomsCount
    propertyType
    enable
`;

export const GQEstateTableMainFields = `
    ${GQEstateMainFields} 
    
    materialType
    main_metro_id

    description
    youtubeId
    
    totalArea
    livingArea
    kitchenArea
    allRoomsArea
    
    floorNumber
    floorsCount
    
    major_user_ids
    major_users {
        ${GQUserMainFields}
    }
   
    price
    currency
    mortgage
    saleType
    createTime
    updateTime
    
    metros {
        ${GQMetroFiled}    
    }
    
    base 
    photosCount
    isFavorite
    isHidden
    exportingErrorsCount
    
    newbuilding_id
    newbuilding {
        ${GQNewBuildingFields}
    }
    newbuilding_house_id
    newbuildingHouse {
        ${GQNewBuildingHouseFields}
    }
    
    buildYear
    buildingIsNotReadyYet
    
    cityDistrict
    cityDistrictType
    
    inner_id
    agency{
        mls_company_id
        shortTitle
        title
        color
        url
        logoUrl
        phone
    }
    
    priceHistory{
        price
        currency
        time
    }
`;

const GQMainBaseFields = `
    exportingBaseCount
    showingsCount
    callsCount
    lastOutgoingCallToOwnerTime
    exclusiveDealId
    isFake
    deleteStatus
    soldPrice
    deleteTime
    
    agentBonusType
    agentBonusValue
    agentBonusCurrency
`;

const GQOwnersBaseFields = `
    isAlreadySold
    owner_source_id
    source
    sourceInnerId
    ownerName
    soldPrice
    ownerPhones {
        phone_id
        phone
        protected
        createTime
    }
`;

const GQExternalBaseFields = `
    source
    sourceInnerId
    agencyName
    
    agentBonusType
    agentBonusValue
`;

const GQMLSBaseFields = `
    subagent {
        email
        firstName
        lastName
        avatarUrl
    }
`;

export const GQEstateTableFields = `
    ${GQEstateTableMainFields}
    
    export_user_ids
    export_users{
        ${GQUserMainFields}
    }
    
    isBooked
    isTop3
    
    comments{
        ${GQComments}
    }
    
    ${GQMainBaseFields}
    ${GQOwnersBaseFields}
    ${GQExternalBaseFields}
`;

const GQEstateFields = `
    ${GQEstateTableMainFields}
    ${GQMLSBaseFields}
  
    ${GQAddressFields}
    
    photos {
        filename
        isPlan
        watermark
        isLoading
    }
    
    flatNumber
    cadastralNumber
    
    buildingSeries
    ceilingHeight
    passengerLiftsCount
    cargoLiftsCount
    hasGarbageChute
    hasRamp
    parking
    
    isApartments
    isPenthouse
    hasOwnershipRight
    isLayoutApproved
  
    windowsView
    loggiasCount
    balconiesCount
    separateWcsCount
    combinedWcsCount
    repairType
    decoration
    hasPhone
    
    shareAmount
    roomsForSaleCount
    roomType
    
    hasInternet
    hasFurniture
    hasKitchenFurniture
    hasTv
    hasWasher
    hasConditioner
    hasBathtub
    hasShower
    hasDishwasher
    hasFridge
    petsAllowed
    childrenAllowed
    
    utilitiesIncluded
    otherUtilities
    otherUtilitiesPayment
    operationalCostsIncluded
    bargainAllowed
    bargainPrice
    bargainConditions
    leaseTermType
    prepayMonths
    deposit
    clientFee
    agentFee
    
    vatType
    contractType
    
    contact_id
    
    minArea
    conditionType
    isOccupied
    layout
    furniturePresence
    isLegalAddressProvided
    availableFrom
    waterPipesCount
    taxNumber

    parkingType
    parkingPlacesCount
    parkingPriceMonthly
    parkingCurrency
    parkingIsFree

    totalAreaBuilding
    buildingName
    buildingType
    buildingClassType
    developer
    managementCompany
    ventilationType
    conditioningType
    extinguishingSystemType
    statusType
    accessType
    businesshouse_id
    heatingType
    ceilingHeight

    hasCarWash
    hasBuffet
    hasCarService
    hasCanteen
    hasCentralReception
    hasHotel
    hasAtm
    hasExhibitionAndWarehouseComplex
    hasPharmacy
    hasBankDepartment
    hasCinema
    hasCafe
    hasMedicalCenter
    hasBeautyShop
    hasStudio
    hasNotaryOffice
    hasPool
    hasClothesStudio
    hasWarehouse
    hasPark
    hasRestaurant
    hasFitnessCentre
    hasSupermarket
    hasMinimarket
    hasShoppingArea
    hasConferenceRoom

    landArea
    landAreaUnitType
    landType

    vatType
    contractType
    
    documentsCount
    dealId
    
    settlementName
    settlement_cian_id
    bedroomsCount
    wcLocationType
    landStatus
    lotCadastralNumber
    
    highway_id
    distanceFromMKAD
    
    hasSecurity
    hasBathhouse
    hasGarage
    hasGas
    hasDrainage
    hasElectricity
    hasWater
    
    garageType
    garageStatus
    hasLight
    hasHeating
    hasExtinguishingSystem
    hasTire
    hasInspectionPit
    hasVideoSurveillance
    hasHourSecurity
    hasAutomaticGates
    hasEntryByPass
    hasBasement
    
    inputType
    hasShopWindows
    
    possibleToChangeLandStatus
    permittedUseType
    possibleToChangePermittedUseType
    hasInvestmentProject
    hasEncumbrances
    electricityLocationType
    electricityPossibleToConnect
    electricityPower
    gasLocationType
    gasPossibleToConnect
    gasCapacity
    gasPressureType
    drainageLocationType
    drainagePossibleToConnect
    drainageCapacity
    drainageType
    waterLocationType
    waterPossibleToConnect
    waterCapacity
    waterType
    drivewayType
    
    floorMaterialTypeType
    parkingPurposeType
    liftType
    liftAdditionalType
    liftCount
    liftLoadCapacity
    cranageType
    cranageLoadCapacity
    cranageCount
    gatesType
    columnGrid
    hasSafeCustody
    isCustoms
    hasTransportServices
    hasOfficeSpace
    parkingLocationType
    parkingPriceEntry
    
    placementType
    hasFoodCourt
    hasSlotMachines
    hasAquapark
    hasBabySitting
    hasRink
    hasBowling
    hasGameRoom
    workingDaysType
    tenants
    openingHoursType
    openingHoursFrom
    openingHoursTo
    shoppingCenterScaleType
    houseLineType
    
    businessPropertyType
    businessEstateType
    hasEquipment
    estateType
    
    externalPhones {
        phone_id
        phone
    }
`;

export const fetchItem = async (id: number, base?: string | null): Promise<Estate> => {
    const graphql = `{
        fetchEstate(estate_id: ${id}, base: ${base || ESTATE_BASE_MAIN}) {
            ${GQEstateFields}
            ${base === ESTATE_BASE_EXTERNAL ? GQExternalBaseFields : base === ESTATE_BASE_OWNERS ? GQOwnersBaseFields : GQMainBaseFields}
        } 
      }`;

    const data = await fetchApi.postGQ<{ fetchEstate: Estate }>(graphql);
    return data.fetchEstate;
};

export const fetchList = async (
    limit: number,
    offset: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    estateFilter: Partial<EstateFilter>,
    controller?: AbortController
): Promise<{ list: EstateTableItem[]; count: number }> => {
    const graphql = `{
        fetchEstates(limit: ${limit}, offset: ${offset}, orderBy: "${orderBy}", 
        sort: "${orderDirection}", filter: ${objectToGraphql(estateFilter)}) {
            list {${GQEstateTableFields}}
            count
        }
      }`;

    const data: { fetchEstates: { list: EstateTableItem[]; count: number } } = await fetchApi.getGQ(graphql, controller);
    return data.fetchEstates;
};

export const fetchListCount = async (
    estateFilter: Partial<EstateFilter>,
    orderBy: string,
    controller?: AbortController
): Promise<number> => {
    const graphql = `{
        fetchEstatesListCount(filter: ${objectToGraphql(estateFilter)}, orderBy: "${orderBy}")
      }`;

    const data = await fetchApi.getGQ<{ fetchEstatesListCount: number }>(graphql, controller);
    return data.fetchEstatesListCount;
};

export const saveItem = async (estate_id: number, estate: Estate): Promise<number> => {
    const graphql = `mutation { 
          updateEstate(estate_id: ${estate_id}, estateDiff: ${objectToGraphql(estate)})
        }`;

    const data: { updateEstate: number } = await fetchApi.postGQ(graphql);
    return data.updateEstate;
};

export const createItem = async (estate: Estate): Promise<number> => {
    const graphql = `mutation { 
          createEstate(estateInput: ${objectToGraphql(estate)})
        }`;

    const data: { createEstate: number } = await fetchApi.postGQ(graphql);
    return data.createEstate;
};

export const fetchAllMetro = async (): Promise<MetroStation[]> => {
    const graphql = `{
        allMetroStations {
            metro_id
            name
            color
            order
            metro_line_id
          }
      }`;

    const data: { allMetroStations: MetroStation[] } = await fetchApi.getGQ(graphql);
    return data.allMetroStations;
};

export const fetchNearestMetros = async (geo_lon: number, geo_lat: number, range: number): Promise<MetroDistance[]> => {
    const graphql = `{
        findNearestMetros(geo_lon:${geo_lon}, geo_lat:${geo_lat}, range:${range}) {
            metro_id
            distance
            name
            color
            transportType
            time
          }
      }`;

    const data: { findNearestMetros: MetroDistance[] } = await fetchApi.getGQ(graphql);
    return data.findNearestMetros;
};

export const estatesByContact = async (contact_id: number): Promise<Estate[]> => {
    const graphql = `{
        estatesByContact(contact_id: ${contact_id}) {
            base
            type
            address
            roomsCount
            propertyType
            estate_id
            createTime
            enable
        } 
      }`;

    const data: { estatesByContact: Estate[] } = await fetchApi.getGQ(graphql);
    return data.estatesByContact;
};

export const findMOSCOWMAPHouse = async (
    town: string,
    street: string,
    streetType: string,
    houseNumber: string,
    houseNumberSokr: string
): Promise<MOSCOWMAP_House> => {
    const graphql = `{
        findMOSCOWMAPHouse(town: "${town}", street: "${street}", streetType: "${streetType}", houseNumber: "${houseNumber}", houseNumberSokr: "${houseNumberSokr}",) {
            house_id
            address
            year
            type
            series
            floors
            flats
            garbage
            floor_height
            porchs
            elevators
        } 
      }`;

    const data: { findMOSCOWMAPHouse: MOSCOWMAP_House } = await fetchApi.getGQ(graphql);
    return data.findMOSCOWMAPHouse;
};

export const fetchItemsOnMap = async (
    estateFilter: Partial<EstateFilter>,
    coordinates: CoordinatesSquare,
    limit: number,
    orderBy: string,
    orderDirection: OrderDirectionType,
    controller?: AbortController
): Promise<Estate[]> => {
    const graphql = `{
        fetchEstatesOnMap(filter: ${objectToGraphql(estateFilter)}, orderBy: "${orderBy}", sort: "${orderDirection}", 
                            coordinates: ${objectToGraphql(coordinates)}, limit: ${limit}) {
            propertyType
            estate_id
            type
            base
            price
            geo_lat
            geo_lon
        } 
      }`;

    const data: { fetchEstatesOnMap: Estate[] } = await fetchApi.getGQ(graphql, controller);
    return data.fetchEstatesOnMap;
};

export const fetchItemsCountOnMap = async (
    estateFilter: Partial<EstateFilter>,
    coordinates: CoordinatesSquare,
    orderBy: string,
    controller?: AbortController
): Promise<number> => {
    const graphql = `{
        fetchEstatesListCount(filter: ${objectToGraphql(estateFilter)}, coordinates: ${objectToGraphql(coordinates)}, orderBy: "${orderBy}")
      }`;

    const data = await fetchApi.getGQ<{ fetchEstatesListCount: number }>(graphql, controller);
    return data.fetchEstatesListCount;
};

export const duplicateEstate = async (estate_id: number): Promise<number> => {
    const graphql = `mutation { 
          duplicateEstate(estate_id: ${estate_id})
        }`;

    const data: { duplicateEstate: number } = await fetchApi.postGQ(graphql);
    return data.duplicateEstate;
};

export const fetchAllHighways = async (): Promise<Highway[]> => {
    const graphql = `{ 
          fetchAllHighways {
            highway_id
            name
          }
        }`;

    const data: { fetchAllHighways: Highway[] } = await fetchApi.getGQ(graphql);
    return data.fetchAllHighways;
};

export const searchEstateDropdown = async (
    searchFilter: Partial<EstateFilter>,
    offset = 0,
    limit = 50,
    abortController: AbortController
): Promise<{ list: TEstateDropdown[]; count: number }> => {
    const graphql = `{ 
          searchEstateDropdown (filter: ${objectToGraphql(searchFilter)}, offset: ${offset}, limit: ${limit}) {
            list {
                ${GQEstateTableMainFields}
                photos {
                    filename
                }
            }
            count  
          }
        }`;

    const data: { searchEstateDropdown: { list: TEstateDropdown[]; count: number } } = await fetchApi.getGQ(graphql, abortController);
    return data.searchEstateDropdown;
};

export const manageItemArray = async (estate_ids: ShareEstateId[], params: ManageEstateArrayType): Promise<boolean> => {
    const graphql = `mutation { 
        manageEstatesArray(estate_ids: ${objectToGraphql(estate_ids.map(({ estate_id }) => estate_id))}, 
            params: ${objectToGraphql(params)}
        )}`;

    const data: { manageEstatesArray: boolean } = await fetchApi.postGQ(graphql);
    return data.manageEstatesArray;
};

export const fetchItemHistory = async (estate_id: number, base?: string): Promise<HistoryChange[]> => {
    const graphql = `{
        fetchEstateChangeHistory(estate_id: ${estate_id}, base: "${base}") {
            ${GQHistoryChange}     
        } 
      }`;

    const data: { fetchEstateChangeHistory: HistoryChange[] } = await fetchApi.getGQ(graphql);
    return data.fetchEstateChangeHistory;
};

export const toggleFavoriteEstate = async (estate_id: number, base: ESTATE_BASES, favorite: boolean): Promise<boolean> => {
    const graphql = `mutation {
        toggleFavoriteEstate(estate_id: ${estate_id}, base: ${base}, favorite: ${String(favorite)})
      }`;

    const data: { toggleFavoriteEstate: boolean } = await fetchApi.postGQ(graphql);
    return data.toggleFavoriteEstate;
};

export const toggleHideEstate = async (estate_id: number, base: ESTATE_BASES, hidden: boolean): Promise<boolean> => {
    const graphql = `mutation {
        toggleHideEstate(estate_id: ${estate_id}, base: ${base}, hidden: ${String(hidden)})
      }`;

    const data: { toggleFavoriteEstate: boolean } = await fetchApi.postGQ(graphql);
    return data.toggleFavoriteEstate;
};

export const takeEstateToWork = async (estate_id: number) => {
    const graphql = `mutation {
        takeEstateToWork(estate_id: ${estate_id})
      }`;

    const data: { takeEstateToWork: boolean } = await fetchApi.postGQ(graphql);
    return data.takeEstateToWork;
};

export type TSameEstatesFilter = {
    approximation: number;
    sameEstateLocationType: 0 | 1 | 2;
    disableExternal: boolean;
    enable: boolean;
    startTime: number | null;
    endTime: number | null;
    deltaTime: number | null;
};

export const fetchSameEstates = async (
    estate_id: number,
    base: ESTATE_BASES,
    filter: TSameEstatesFilter
): Promise<{ list: EstateTableItem[]; count: number }> => {
    const graphql = `{
        fetchSameEstates(estate_id: ${estate_id}, base: ${base}, sameEstateFilter: ${objectToGraphql(filter)}) {
            list {
                ${GQEstateTableFields}
                whetherIsInSma
            }
            count
        } 
      }`;

    const data: { fetchSameEstates: { list: EstateTableItem[]; count: number } } = await fetchApi.getGQ(graphql);
    return data.fetchSameEstates;
};

export const fetchOwnFixedPriceEstates = async (estate_id: number): Promise<MlsEstateFixPrice[]> => {
    const graphql = `{
        fetchOwnFixedPriceEstates(estate_id: ${estate_id}) {
            ${GQMlsFixPriceMainFields}
        } 
      }`;

    const data: { fetchOwnFixedPriceEstates: MlsEstateFixPrice[] } = await fetchApi.getGQ(graphql);
    return data.fetchOwnFixedPriceEstates;
};
