import React, { Component } from 'react';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import { UploadingPhoto } from '~/types/estate.types';
import config from '../../../../config/ui.config';
import PreventDblClick from '../../../Base/PreventDblClick';
import { WarningMessage, MessageTitle } from '~ui/Message';

import PhotosListBlockEditing from './PhotosListBlockEditing';
import YoutubeBlockEditing from './YoutubeBlockEditing';

import PhotosUpload from '~/components/Base/Photos/PhotosUpload';

type PhotosBlockEditingProps = {
    estate_id: number;
    photos?: Array<UploadingPhoto>;
    youtubeId?: string | null;
};

@observer
class PhotosBlockEditing extends Component<PhotosBlockEditingProps> {
    constructor(props: PhotosBlockEditingProps) {
        super(props);

        const estate = {
            estate_id: props.estate_id,
            photos: props.photos || [],
            youtubeId: props.youtubeId || null
        };
        estateStore.setEditingItem(estate.estate_id, estate);
    }

    onLoadFile = (error, file) => {
        if (error) {
            return;
        }
        const { serverId } = file;

        const { response }: { response: UploadingPhoto } = JSON.parse(serverId);
        response.watermark = config.estatePhotos.watermarks instanceof Array ? 0 : null;
        estateStore.addPhotoTemp(this.props.estate_id, response);
    };

    render() {
        const { estate_id } = this.props;

        return (
            <PreventDblClick>
                <br />
                <WarningMessage enableClose>
                    <MessageTitle>Внимание! Убедитесь, что:</MessageTitle>
                    <ul>
                        <li>На фотография отсутствуют посторонние водяные знаки или следы их ликвидации</li>
                        <li>На фотографиях нет людей</li>
                        <li>Все фотографии относятся ТОЛЬКО к данному объекту</li>
                        <li>На фотографиях отсутствует текст/надписи и фотографии не являются склейками/коллажами</li>
                    </ul>
                </WarningMessage>

                <PhotosUpload onLoadFile={this.onLoadFile} />
                <PhotosListBlockEditing estate_id={estate_id} />
                <YoutubeBlockEditing {...this.props} />
            </PreventDblClick>
        );
    }
}

export default PhotosBlockEditing;
