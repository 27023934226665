import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Card, Embed, Accordion, Icon, Header } from 'semantic-ui-react';

import deepCopy from '~/common/deepCopy';
import estateStore from '~/stores/estateStore';
import commonStore from '~/stores/commonStore';

import EditingBlockWrapper, { EditingWrappedProps } from '../../EditingBlockWrapper';
import PhotosBlockEditing from '../EditBlocks/PhotosBlockEditing';

import { WarningMessage, MessageTitle } from '~ui/Message';

import PhotoGalleryMobile from '~/components/Base/Photos/PhotoGalleryMobile';
import PhotoGalleryDesktop from '~/components/Base/Photos/PhotoGalleryDesktop';

type PhotosBlockShowState = {
    showYoutubeAccordion: boolean;
};

@observer
class PhotosBlockShow extends Component<EditingWrappedProps, PhotosBlockShowState> {
    constructor(props: EditingWrappedProps) {
        super(props);

        this.state = {
            showYoutubeAccordion: false
        };
    }

    handleToggleYoutube = () => {
        this.setState({ showYoutubeAccordion: !this.state.showYoutubeAccordion });
    };

    render() {
        const { isMobile } = commonStore;
        const { item_id, editing } = this.props;
        const {
            item,
            loadingItem,
            property: { updatingPhotos }
        } = estateStore.getItem(item_id);

        const { photos, youtubeId } = item || {};
        const { showYoutubeAccordion } = this.state;

        return (
            <div className="crm-Item__editingMode">
                {!editing && !loadingItem && photos.length === 0 && (
                    <WarningMessage>
                        <MessageTitle>У данного объекта нет фотографий!</MessageTitle>
                    </WarningMessage>
                )}

                {(updatingPhotos || loadingItem || photos.length > 0) && !editing && (
                    <Fragment>
                        {isMobile && <PhotoGalleryMobile photos={photos} loadingItem={loadingItem} updatingPhotos={updatingPhotos} />}
                        {!isMobile && <PhotoGalleryDesktop photos={photos} loadingItem={loadingItem} updatingPhotos={updatingPhotos} />}
                    </Fragment>
                )}

                {editing && (
                    <Card.Content>
                        <PhotosBlockEditing estate_id={item_id} photos={deepCopy(Array.from(photos))} youtubeId={youtubeId} />
                    </Card.Content>
                )}
                {!editing && youtubeId && (
                    <Accordion>
                        <Accordion.Title active={showYoutubeAccordion} onClick={this.handleToggleYoutube}>
                            <Header size="small">
                                <Header.Content>
                                    <Icon name="dropdown" />
                                    <Icon name="youtube" color="red" /> YouTube ролик
                                </Header.Content>
                            </Header>
                        </Accordion.Title>
                        <Accordion.Content active={showYoutubeAccordion}>
                            {showYoutubeAccordion && (
                                <Embed
                                    id={youtubeId}
                                    placeholder={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
                                    source="youtube"
                                />
                            )}
                        </Accordion.Content>
                    </Accordion>
                )}
            </div>
        );
    }
}

export default EditingBlockWrapper(estateStore, PhotosBlockShow, null, { disableSwapping: true });
