import serverConfig from '../config/server.config';
import {
    ESTATE_BASE_ALL, ESTATE_BASE_MAIN, ESTATE_BASE_MLS, ESTATE_BASE_MY,
    ESTATE_BASE_OWNERS, ESTATE_BASE_FAVORITES, ESTATE_BASE_FREE, ESTATE_BASE_EXTERNAL
} from "../types/estate.types";

const serverUrl = serverConfig.serverUrl;

export default {
    serverUrl,
    defaultAvatarUrl: serverUrl + '/public/no-photo.png',
    publicUrl: serverUrl + '/public/',
    shareUrl: document.location.origin + '/share/',
    daData: {
        token: '40ab2b3e13b3a0fcb09936718d8671d0f684a656',
        addressUrl: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
        emailUrl: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/email',
        nameUrl: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio'
    },

    yandexMap: {
        url: 'https://geocode-maps.yandex.ru/1.x/?format=json&geocode='
    },

    defaultCoords: [55.753215, 37.622504],

    estatePhotos: {
        sizes: [
            { width: 1280, dir: 'big', prefix: 'big_' },
            { width: 640, dir: 'middle', prefix: 'middle_' },
            { width: 320, dir: 'thumbs', prefix: 'thumb_' }
        ],
        estatePhotoUrl: 'https://data.ogrk24.ru/photos/',
        estateTempPhotoUrl: serverUrl + '/tmpuploads/',

	watermarks: [
            { title: 'Логотип компании (Левый нижний угол)', color: 'green' },
            { title: 'Логотип компании (Правый нижний угол)', color: 'red' },
            { title: 'Логотип компании (По центру)', color: 'blue' },
            { title: 'Название компании (По центру)', color: 'brown' },
            { title: 'Нет', color: 'grey' }
        ]
    },

    documentsDir: serverUrl + '/document/download',
    learningDir: serverUrl + '/learningMaterials/download',

    estateListMenuOrder: [
        // [ESTATE_BASE_ALL, 'Все'],
	[ESTATE_BASE_MY, 'Мои'],
        [ESTATE_BASE_MAIN, 'Объекты компании'],
        [ESTATE_BASE_OWNERS, 'Собственники'],
	[ESTATE_BASE_FAVORITES, 'Избранные'],
	[ESTATE_BASE_FREE, 'Свободные'],
//	[ESTATE_BASE_EXTERNAL, 'Внешние'],
	[ESTATE_BASE_MLS, 'МЛС']
    ],

    enableUsersSEO: true,

    enableFakeEstate: false,

    defaultPageSize: 30,

    outerEstateLinkToSite: 'https://ogrk-center.ru/estate/[estate_id]',

    userShare: {
        link: 'https://www.ogrk-center.ru/about/best-realtors/[siteLink]/',
        title: (firstName, lastName) => `Риэлтор ${firstName} ${lastName} – контакты, объекты риэлтора`,
        text: (firstName, lastName) => `База риэлторов ОГРК-Центр - ${firstName} ${lastName} - информация о риэлторе, контактная информация`
    },

    CANONICAL_EXPORT_BASES_ID: [1, 4, 103]
};
