import React, { useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';

import estateStore from '~/stores/estateStore';
import TextInput from '~ui/TextInput';

const clearYoutubeId = link => {
    let ID = '';
    const url = link.replace(/(>\|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_-]/i);
        ID = ID[0];
    }
    return ID;
};

const YOUTUBE_URL = 'https://www.youtube.com/watch?v=';

const YoutubeBlockEditing = (props: { estate_id: number; youtubeId?: string }) => {
    const { estate_id } = props;

    useMemo(() => {
        const estate = {
            youtubeId: props.youtubeId || null
        };
        estateStore.setEditingItem(estate_id, estate);
    }, [estate_id]);

    const handleYoutubeChange = (event: React.SyntheticEvent, { value }: { value: string }) => {
        estateStore.setEditingItem(estate_id, { youtubeId: clearYoutubeId(value) });
    };

    const { youtubeId } = estateStore.getItem(estate_id).editingItem;

    return (
        <div className="crm-Item__editingMode">
            <Grid container alignItems="center">
                <Grid item xs={4}>
                    <label className="crm-Estate__field_label">Ролик YouTube</label>
                </Grid>
                <Grid item xs={8}>
                    <TextInput
                        type="text"
                        label="Ссылка на ролик в YouTube"
                        size="small"
                        value={youtubeId && youtubeId.length > 0 ? `${YOUTUBE_URL}${youtubeId}` : ''}
                        name="buildYear"
                        onChange={handleYoutubeChange}
                        fullWidth
                        variant="outlined"
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default observer(YoutubeBlockEditing);
